<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="관련 사고보고서 목록"
        title="LBL0002632"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="emerActPlanDocu.processAccidents"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="iimAccidentId"
        selection="multiple"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable && !disabled" :showLoading="false" label="등록" icon="add" @btnClicked="searchProcessAccident" />
            <c-btn v-if="editable && !disabled && emerActPlanDocu.processAccidents.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="removeProcessAccident" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'emergency-action-accident',
  props: {
    emerActPlanDocu: {
      type: Object,
      default: () => ({
        plantCd: '',
        sopEmergencyPlanId: '',
        processAccidents: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "accidentNo",
            field: "accidentNo",
            label: "사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentName",
            field: "accidentName",
            label: "사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            label: "공정",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentKindName",
            field: "accidentKindName",
            style: 'width:150px',
            label: "사고종류",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentGradeName",
            field: "accidentGradeName",
            label: "사고등급",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            style: 'width:100px',
            label: "LBL0002309",
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceDeptName",
            field: "occurrenceDeptName",
            label: "발생부서",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
        height: '780px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    searchProcessAccident() {
      this.popupOptions.title = '관련 공정사고 검색';
      this.popupOptions.param = {
        type: 'multiple',
        accidentStatusCd: 'ISPC000005'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAccidentPopup;
    },
    closeAccidentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveProcesses = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.emerActPlanDocu.processAccidents, { iimAccidentId: item.iimAccidentId }) === -1) {
            this.emerActPlanDocu.processAccidents.push({
              iimAccidentId: item.iimAccidentId,
              accidentNo: item.accidentNo,
              accidentName: item.accidentName,
              processName: item.processName,
              accidentKindName: item.accidentKindName,
              accidentGradeName: item.accidentGradeName,
              occurrenceDate: item.occurrenceDate,
              occurrenceDeptName: item.occurrenceDeptName,
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              editFlag: 'C',
            })
            saveProcesses.push({
              iimAccidentId: item.iimAccidentId,
              accidentName: item.accidentName,
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              editFlag: 'C',
            })
          }
        })
        this.$http.url = transactionConfig.sop.eap.relateAccident.insert.url;
        this.$http.type = 'POST';
        this.$http.param = saveProcesses;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
    removeProcessAccident() {
      let selectData = this.$refs['accidentTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.eap.relateAccident.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                this.emerActPlanDocu.processAccidents = this.$_.reject(this.emerActPlanDocu.processAccidents , _item)
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              },);
              this.$refs['accidentTable'].selected = []
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    linkClick(tag) {
      this.popupOptions.title = '관련 공정사고 상세';
      this.popupOptions.param = 
      this.popupOptions.param = {
        iimAccidentId: tag ? tag.iimAccidentId : '',
        stepCd: tag ? tag.accidentStatusCd : '',
        count: 0,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    }
  }
};
</script>